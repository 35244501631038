<template>
  <div>
    <v-row class="pa-0">
      <v-col class="pa-0 px-1 pt-2" v-for="item in countryes_map" :key="item.country">
        <CountCountryesSettings :data="courses[`${item.country}`]" style="height: 100%" :flag="item['flag']" :title="item['title']"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Api from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'CounterByCountry',
  components: {
    CountCountryesSettings: () => import('./counter_by_country/CountCountryesSettings.vue'),
  },
  props: {
    current_year: {
      type: Number,
      default: ()=> this.$dayjs().year()
    },
  },
  data() {
    return {
      courses: {},
      countryes_map: [
        { title: 'Brasil', country: 'brazilian', flag: 'br' },
        { title: 'Argentina',country: 'argentine', flag: 'ar' },
        { title: 'Colômbia', country: 'colombian', flag: 'col' },
        { title: 'Paraguai', country: 'paraguayan', flag: 'py' },
        { title: 'Uruguai', country: 'uruguayan', flag: 'uy' },
        { title: 'Outros', country: 'others', flag: 'other' },
      ]
    }
  },
  methods: {
    getData(){
      this.getDashCounter()
    },
    getDashCounter(kind='done_form_enrollment_country', filter = {country_counter_options: 'all_enrollment_by_country', current_year: this.current_year}){
      Api.DashCounter.show(kind, filter).then(r=>r.data).then(d=>{
        this.courses = {...d}

      }).catch((err) => this.addNotification({type: 'error', msg: `${err}`}));
    },
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  watch: {
    current_year(){
      this.getData()
    }
  },
  mounted () {
    this.getData()
  },  
}
</script>

<style lang="scss" scoped>

</style>